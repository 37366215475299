import React from "react";
import BaseLayout from "../components/layouts/baseLayout";
import { Container, Row, Col, Button } from "react-bootstrap";
import ChildWearingMaskImage from "../components/images/childWearingMask";
import TextJumbotron from "../components/util/textJumbotron";

function GreenTick() {
  return (
    <span role="img" aria-label="green tick mark">
      ✅
    </span>
  );
}
export default function CovidPage({ data }) {
  return (
    <BaseLayout pageTitle="GMA Covid Response">
      <section>
        <TextJumbotron heading="Covid Response" />
        <Container>
          <Row>
            <Col>
              <div className="border boder-dark p-3 mb-3">
                <h2 className="text-center">
                  CHECK YOUR CHILD DAILY FOR SYMPTOMS OF COVID-19
                </h2>
                <p class="lead text-center pb-2">
                  Everyday before you drop off your child, please complete this
                  self-assessment and hand to your child's teacher.
                </p>
              </div>
              <p>
                Great Minds knows that as a childcare provider, we are entrusted
                with the task of keeping your children healthy and safe. This is
                our number one priority, and we do not take it lightly. With
                that in mind, we are going above and beyond government
                regulations to ensure the safety of your children upon returning
                to our state of the art learning environments.
              </p>
              <p>
                Below, you will find more information about all the enhancements
                being added to the centres. We are taking every precaution
                possible to ensure the health and safety of all of our staff and
                our families.
              </p>
              <h2 className="text-center p-3">COVID-19 Enhancements</h2>
              <p>
                <strong>
                  <GreenTick /> Scheduled Time Slots
                </strong>
              </p>
              <p>
                To ensure physical distancing measures are being met, we are
                scheduling both pick-up and drop-off time slots to limit traffic
                within the centre.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <p>
                <strong>
                  <GreenTick /> Temperature Checks
                </strong>
              </p>
              <p>
                Upon arrival the next morning at our centres, one of our team
                members will take your child’s temperature with a contactless
                thermometer to determine if they are healthy or running a fever.
                If the reading comes back normal, they will enter the facility,
                and if they are running a fever, they will be asked to return
                home. All of our team members will be screened and checked every
                day as well to ensure they are healthy and can be in our
                centres. Upon getting approval to enter our facilities, hand
                sanitizer will be readily available for those who need it.{" "}
              </p>
            </Col>
            <Col md={7}>
              <ChildWearingMaskImage className="rounded" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <strong>
                  <GreenTick /> Sanitization
                </strong>
              </p>
              <p>
                We have extra staff members to ensure the sterilization process
                throughout the centre is completed thoroughly. They will ensure
                all areas of our centres and our toys are properly sterilized
                throughout each day to ensure the health and safety of everyone.
              </p>
              <p>
                <strong>
                  <GreenTick /> Physical Distancing
                </strong>
              </p>
              <p>
                We will be implementing physical distancing measures to decrease
                the risk of transmission. While physical distancing measures
                will be in place, children will still be able to interact
                socially with one another to facilitate a healthy learning
                environment.
              </p>
              <p>
                <strong>
                  <GreenTick /> Pre-Screening Covid Daily Checklist Form
                </strong>
              </p>
              <p>
                Parents are required to submit the health form prior to every
                visit. This is done to ensure that everyone entering the centre
                is in good health.
              </p>
              <p>
                <strong>
                  <GreenTick /> PPE For Teachers
                </strong>
              </p>
              <p>
                As an extra layer of precaution, our teachers and staff will
                have access to necessary PPE. In addition to all of the
                enhancements above, all of the same practices will be applied to
                our entire staff where necessary, to ensure everyone entering
                our facilities is in good health to keep your children safe.
              </p>
              <p>
                If you have any other questions regarding our COVID-19
                enhancements, please feel free to contact us with any questions
                you may have. We look forward to seeing and serving you again.
              </p>
              <Button
                className="mb-3 mt-3"
                variant="secondary"
                size="lg"
                block
                href="forms/Covid_Daily_Checklist.pdf"
              >
                Covid Pre-screening form
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </BaseLayout>
  );
}
